<template>
  <div>
    <div class="contentFrame">
      <div class="leftSide">
        <div class="subinfotext">お問い合わせ・Q&amp;A
        </div>
        <div class="sentent_r1">
よくあるご質問と回答集をご用意しています。わからないことがございましたら、お問い合わせの前にまず、ご覧ください。
<p>それでも解決できない方は「<router-link @click.native="scrollToTop" to="/informations/contact">&gt;&gt;お問合せフォーム</router-link>」へ</p>
        </div>


<div v-if="loaded">


    <div class="section" v-for="q,a in qa" :key="a">

    <div class="sectiongroup">
    <h3 v-html="q.title"></h3>

    <div class="sentent" v-html="q.content"></div></div>
    <ul>
    <li v-for="child,i in q.childs" :key="i">
        <div class="question" @click="qshow(a,i)" v-html="'Q.'+child.title"></div>

    <transition name="slide-fade">
        <div class="answer" v-show="qstate(a,i)" v-html="'A.'+child.content"></div>
    </transition>
    </li>
    </ul>
    </div>
</div>
<div v-else>
      <div class="loadercontent">
読み込み中です..</div>
</div>

<!---->



      </div>
      <div class="rightSide">
        <sideContents />
      </div>
    </div>
  </div>
</template>

<script>
import qa from "./qa.json";
export default {
  created: function () {
    this.$parent.middle = 2;
      this.loaded_countmax=1;
      this.fetch('qa',{}).then((data)=>{
          console.log(data);
          this.qa=data;
          this.loaded_countup();
      });
    console.log(qa)
  },
  data:function(){return {qa:qa,visiblearr:{}}},
  methods:{
    qstate:function(a,i){console.log(this.visiblearr[a+"-"+i])
      if(typeof this.visiblearr[a+"-"+i]=="undefined"){
        return false;
      }
      return this.visiblearr[a+"-"+i];
    },
    qshow:function(a,i){
      if(typeof this.visiblearr[a+"-"+i]=="undefined"){
        this.$set(this.visiblearr,a+"-"+i,true);
      }else{console.log(this.visiblearr[a+"-"+i])
        this.$set(this.visiblearr,a+"-"+i,!this.visiblearr[a+"-"+i]);
      }
    }
  }
};
</script>

<style scoped lang="scss">
$pc: 1024px; // PC
$tab: 680px; // タブレット
$sp: 480px; // スマホ
@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}

@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}
.section{
  .sectiongroup{
    h3{
      padding: .5em 0;
    }
    text-align: center;
    border:solid 3px #ccc;
    margin-top: .5em;
    padding: .8em;
    border-radius: 1em;
  }
  .sentent{
      padding: .5em 0;
      margin: 0;
      padding-top: 0;
    text-align: center;
    font-size: .85em;
    font-weight: bold;
    color: rgb(95, 47, 47);
  }
  .question{
    margin-top: 1em;
    text-align: left;
  }
  .answer{
    text-align: left;
    border:solid 1px #ccc;
    margin-top: .5em;
    padding: 1em;
    border-radius: 1em;
    background: rgb(248, 248, 248);
  }
  ul{
    list-style: none;
    li{
    border-bottom:solid 1px #ccc;
    padding-bottom: 1em;
    &:last-child{
    border-bottom:none;
    margin-bottom: 3em;
    }
    }
  }
}
.question{
  cursor: pointer;
  &:hover{
    font-weight: bold;
  }
}
.leftSide{
    img{
        max-width: 100%;
    }
}
.address{
  border: solid 1px #ccc;
  border-radius: 1em;
  margin: 1em 0;
  padding: 1em;
  h3{
    font-size: 2em;
  }
}
.subinfotext {
  color: #454552;
  font-size: 1.5em;
  font-weight: bold;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/ilst1.png") no-repeat left center /
    contain;
  @include sp {
    padding-left: 10vw;
    font-size: 1em;
  }
}
.sentent_r1 {
  color: #d72e23;
  line-height: 1.7;
  margin: 1em 0;
}
.sentent {
  text-align: left;
  color: #454552;
  line-height: 1.7;
  margin: 1em 0;
}
  h3 {
    padding: 2em 0;
  }
  h4 {
    margin: 1em 0;
    padding-left: 1em;
    border-left: double 10px #d72e23;
  }
.centeimg1 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 50%;
    max-width: 100%;
    @include sp{
    width: 50%;
    }
  }
}
.centeimg2 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 90%;
    max-width: 100%;
  }
}
.centeimg3 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 60%;
    max-width: 100%;
    @include sp{
    width: 100%;
    }
  }
}
</style>